import {QRCodeSVG} from "qrcode.react";
import React, {useState, useRef} from 'react';
import {
    QrcodeIcon,
    MinusIcon,
    PrinterIcon,
    LinkIcon
} from "@heroicons/react/outline";
import {ReactToPrint} from "react-to-print";
import {Buffer} from "buffer";

const QRCodeModal = ({ name, userId, artifactId, hasAudio }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef();

    const rawParams = `uu=${userId}&aa=${artifactId}`;
    const encoded = Buffer.from(rawParams, 'binary').toString('base64');
    const qrCodeContentUrl = `${process.env.REACT_APP_ARTIFACTS_PAGE_URL_PREFIX}/${encoded}`;

    const goto = (url) => {
        window.open(url);
    }

    return (
        <div className="p-4">
            <button onClick={() => setIsOpen(true)}
                    disabled={!hasAudio}
                    className={`mr-2 p-1 bg-transparent ${hasAudio ? 'text-green-500 hover:text-green-700' : 'bg-gray-500'}`}>
                <QrcodeIcon className='w-5'/>
            </button>

            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div>
                        <div className="flex justify-between items-center bg-white">
                            <button
                                onClick={() => goto(qrCodeContentUrl)}
                                className="text-indigo-600 p-2 bg-transparent border-none hover:bg-gray-500">
                                <LinkIcon className='w-5'/>
                            </button>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="text-indigo-600 p-2 bg-transparent border-none hover:bg-gray-500">
                                <MinusIcon className='w-5'/>
                            </button>
                        </div>
                        <div ref={contentRef}
                            className="bg-white shadow-xl p-3">
                            <div className="flex justify-center items-center mb-1">
                                <h2 className="px-24 text-xl font-bold">{name}</h2>
                            </div>
                            <div className="items-center flex justify-center py-4">
                                <QRCodeSVG
                                    value={qrCodeContentUrl}
                                    level="M"
                                    size="196"
                                />
                            </div>
                        </div>
                        <div className="bg-white flex justify-center items-center">
                            <ReactToPrint
                                trigger={() => <button
                                    className="text-indigo-600 p-2 bg-transparent border-none">
                                    <PrinterIcon className='w-6 py-1'/>
                                </button>}
                                content={() => contentRef.current}
                            />

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QRCodeModal;