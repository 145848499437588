import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

import { Endpoints, Pages } from "./consts";

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }

        const signInUrl = `${process.env.REACT_APP_API_URL}/${Endpoints.SignIn}`;
        axios.post(signInUrl,
            { email, password },
            { headers }
        ).then((response) => {
            const userData = response.data;
            console.log(userData);
            navigate(Pages.UserHome, {
                state: userData,
            });
        }).catch((err) => {
            alert(err)
        })
    }

    return (
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="max-w-sm mx-auto">
                <div className="text-center">
                    {/*TODO [Launch Blocker]: Change to SageWhisper Logo*/}
                    <img className="w-auto h-12 mx-auto"
                         src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/logo-symbol.svg"
                         alt=""/>
                    <h1 className="mt-12 text-3xl font-bold text-gray-900">Login to SageWhisper</h1>
                </div>

                {/*TODO: Add Sign in with Google after Launch*/}
                {/*<div className="mt-12">
                    <button type="button"
                        className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900">
                        <img className="w-5 h-5 mr-2"
                             src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/previews/sign-in/1/google-logo.svg"
                             alt=""/>
                        Sign in with Google
                    </button>
                </div>*/}

                <div className="relative mt-6">
                    <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-200"></div>
                    </div>

                    <div className="relative flex justify-center">
                        <span className="px-2 text-sm text-gray-400 bg-white"/>
                    </div>
                </div>

                <form action="#" method="POST" className="mt-4">
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-900"> Email </label>
                            <div className="mt-2">
                                <input type="email" name="" id="" placeholder="Email address" value={email}
                                       onChange={(e) => {setEmail(e.target.value)}}
                                       className="border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"/>
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="" className="text-sm font-bold text-gray-900"> Password </label>
                                <button title=""
                                   className="text-sm font-medium text-indigo-600 hover:text-indigo-700"> Forgot
                                    Password? </button>
                            </div>
                            <div className="mt-2">
                                <input type="password" name="" id="" placeholder="Password (min. 8 character)" value={password}
                                       onChange={(e) => {setPassword((e.target.value))}}
                                       className="border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"/>
                            </div>
                        </div>

                        <div className="relative flex items-center">
                            <div className="flex items-center h-5">
                                <input type="checkbox" name="remember-password" id="remember-password"
                                       className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"/>
                            </div>

                            <div className="ml-3">
                                <label htmlFor="remember-password"
                                       className="text-sm font-medium text-gray-900"> Remember Me </label>
                            </div>
                        </div>

                        <div>
                            <button type="submit" onClick={handleLogin}
                                className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500">
                                Sign in
                            </button>
                        </div>
                    </div>
                </form>

                <div className="mt-6 text-center">
                    <p className="text-sm font-medium text-gray-900">Don't have an account?
                        <Link to={`..${Pages.SignUp}`} className="font-bold hover:underline"> Sign up now </Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SignIn;