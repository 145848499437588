import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import SignUp from './components/SignUp';
import Login from './components/SignIn';
import UserHome from './components/UserHome';

ReactDOM.render(
  <BrowserRouter>
      <Routes>
          <Route path="/" element={<App />} />
          <Route path='/signup' element={<SignUp/>}/>
          <Route path='/login'  element={<Login/>} />
          <Route path='/userhome' element={<UserHome/>} />
      </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);