import React, {useState, useRef, useEffect, useCallback} from 'react';
import {Endpoints, AllLanguages, LanguageNamesToTickers, OriginalLanguageIdentifier} from "./consts";
import axios from "axios";
import {PencilIcon, PlusIcon} from "@heroicons/react/outline";

const MultiPageForm = ({ userId, artifactId }) => {
    const isCreateNewArtifacts = artifactId === "" || artifactId === null;
    const [isOpen, setIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [formData, setFormData] = useState({
        artifactName: '',
        artifactRawText: '',
        genderOfVoice: '',
        sourceLanguage: '',
        targetLanguages: []
    });
    const [showOptions, setShowOptions] = useState(false);
    const inputRef = useRef(null);

    const MAX_CHARS = 10000;

    const pages = [
        {
            title: 'Artifact Information'
        },
        {
            title: 'Audio Settings'
        }
    ];

    const describeArtifactCallback = useCallback(async () => {
        if (isOpen && !isCreateNewArtifacts) {
            const describedArtifact = await describeArtifact(userId, artifactId);
            if (!describedArtifact) {
                alert(`failed to load details of this artifact. Please refresh the page`);
            } else {

                setFormData(prevData => ({
                    ...prevData,
                    artifactName: describedArtifact.name,
                    genderOfVoice: describedArtifact.supportedGenders[0],
                    artifactRawText: describedArtifact.artifactContentByLanguage,
                    targetLanguages: describedArtifact.supportedLanguages.slice(1),
                }));
            }
        }
    }, [userId, artifactId, isOpen, isCreateNewArtifacts]);

    useEffect(() => {
        describeArtifactCallback();
    }, [describeArtifactCallback]);

    const describeArtifact = async (userId, artifactId) => {
        const describeArtifactUrl = `${process.env.REACT_APP_API_URL}/${Endpoints.DescribeArtifact}/${userId}/${artifactId}/${OriginalLanguageIdentifier}`;
        try {
            const { data: response } = await axios.get(describeArtifactUrl);
            return response;
        } catch (err) {
            console.error("describeArtifact", err);
        }

        return null;
    };

    const languageOptions = Object.values(AllLanguages).map((language) => language.name);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleTargetLanguageSelection = (language) => {
        setFormData(prevData => ({
            ...prevData,
            targetLanguages: prevData.targetLanguages.includes(language)
                ? prevData.targetLanguages.filter(lang => lang !== language)
                : [...prevData.targetLanguages, language]
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log(`submitting workload for ${artifactId}`);
        const submitWorkloadUrl = `${process.env.REACT_APP_API_URL}/${Endpoints.SubmitWorkload}`
        axios.post(submitWorkloadUrl, {
            userId: userId,
            artifactId: artifactId,
        }).then((response) => {
            console.log(`raw save response: ${response}`);
        }).catch((err) => {
            console.log(`error: ${err}`);
        })
    };

    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const saveArtifactUrl = isCreateNewArtifacts
            ? `${process.env.REACT_APP_API_URL}/${Endpoints.CreateArtifact}`
            : `${process.env.REACT_APP_API_URL}/${Endpoints.UpdateArtifact}`;

        console.log(`Saving: `, { artifactName: formData.artifactName, artifactRawText: formData.artifactRawText });
        const selectLanguagesTickers = formData.targetLanguages.map((langName) => LanguageNamesToTickers.get(langName));

        axios.post(saveArtifactUrl, {
            providedId: "",
            name: formData.artifactName,
            artifactId: isCreateNewArtifacts ? "" : artifactId,
            userId: userId,
            rawText: formData.artifactRawText,
            rawTextLanguage: formData.sourceLanguage,
            genders: [formData.genderOfVoice],
            languages: selectLanguagesTickers
        }).then((response) => {
            console.log(`raw save response: ${response}`);
            artifactId = response.data.artifactId;

        }).catch((err) => {
            console.log(`error: ${err}`);
        })
    };

    const goToPage = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setShowOptions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="p-4">
            {artifactId !== "" ?
                <button onClick={() => setIsOpen(true)}
                        className="mr-2 p-1 text-indigo-600 hover:text-indigo-800 bg-transparent border-indigo-800">
                    <PencilIcon className='w-5'/>
                </button>
                :
                <button
                    onClick={() => setIsOpen(true)}
                    className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-800 transition-colors flex items-center">
                    <PlusIcon className='w-7 px-1'/>
                    Create New
                </button>
            }
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white rounded-lg shadow-xl p-6 w-96 max-w-full">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-bold">{pages[currentPage].title}</h2>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="bg-white text-indigo-600 font-bold p-1">
                                X
                            </button>
                        </div>
                        <form>
                            {currentPage === 0 && (
                                <div>
                                    <div className="mb-4">
                                        <label htmlFor="artifactName" className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                                        <input
                                            type="text"
                                            id="artifactName"
                                            name="artifactName"
                                            value={formData.artifactName}
                                            onChange={handleInputChange}
                                            required
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="artifactRawText" className="block text-gray-700 text-sm font-bold mb-2">Audio Text Content</label>
                                        <textarea
                                            id="artifactRawText"
                                            name="artifactRawText"
                                            value={formData.artifactRawText}
                                            onChange={handleInputChange}
                                            maxLength={MAX_CHARS}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
                                        />
                                        <p className="text-sm text-gray-500 mt-1">
                                            {MAX_CHARS - formData.artifactRawText.length} characters remaining
                                        </p>
                                    </div>
                                </div>
                            )}
                            {currentPage === 1 && (
                                <div>
                                    <div className="mb-4">
                                        <p className="block text-gray-700 text-sm font-bold mb-2">Gender Of Voice</p>
                                        <div className="mt-2">
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="genderOfVoice"
                                                    value="male"
                                                    checked={formData.genderOfVoice === 'male'}
                                                    onChange={handleInputChange}
                                                    className="form-radio"/>
                                                <span className="ml-2">Male</span>
                                            </label>
                                            <label className="inline-flex items-center ml-6">
                                                <input
                                                    type="radio"
                                                    name="genderOfVoice"
                                                    value="female"
                                                    checked={formData.genderOfVoice === 'female'}
                                                    onChange={handleInputChange}
                                                    className="form-radio"/>
                                                <span className="ml-2">Female</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="sourceLanguage"
                                               className="block text-gray-700 text-sm font-bold mb-2">
                                            Original Language
                                        </label>
                                        <select
                                            id="sourceLanguage"
                                            name="sourceLanguage"
                                            value={formData.sourceLanguage}
                                            onChange={handleInputChange}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                            <option value="">Select a Language</option>
                                            {languageOptions.map(option => (
                                                <option value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-4" ref={inputRef}>
                                        <label htmlFor="languages"
                                               className="block text-gray-700 text-sm font-bold mb-2">
                                            Translate To</label>
                                        <div
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-text min-h-[40px]"
                                            onClick={() => setShowOptions(true)}>
                                            {formData.targetLanguages.map((lang, index) => (
                                                <span key={index}
                                                      className="inline-block bg-blue-200 rounded-full px-3 py-1 text-sm font-semibold text-blue-700 mr-2 mb-2">
                                                    {lang}
                                                    <button
                                                        type="button"
                                                        className="ml-1 text-blue-700 border-none focus:outline-none"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleTargetLanguageSelection(lang);
                                                        }}>
                                                    &times;
                                                    </button>
                                                </span>
                                            ))}
                                        </div>
                                        {showOptions && (
                                            <div
                                                className="mt-2 bg-white border border-gray-300 rounded shadow-lg max-h-32 overflow-y-auto">
                                                {languageOptions.map((lang, index) => (
                                                    <button
                                                        key={index}
                                                        type="button"
                                                        className={`block w-full text-black text-left px-4 py-2 hover:bg-gray-100 ${
                                                            formData.targetLanguages.includes(lang) ? 'bg-blue-100' : ''
                                                        }`}
                                                        onClick={() => handleTargetLanguageSelection(lang)}>
                                                        {lang}
                                                    </button>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="flex justify-center space-x-2 mt-6">
                                {pages.map((_, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        onClick={() => goToPage(index)}
                                        className={`w-3 h-3 rounded-full ${
                                            currentPage === index ? 'bg-blue-500' : 'bg-gray-300'
                                        }`}
                                        aria-label={`Go to page ${index + 1}`}/>
                                ))}
                            </div>
                            {currentPage === pages.length - 1 && (
                                <div className="mt-6 flex justify-end space-x-3">
                                    <button
                                        onClick={handleSave}
                                        className="px-4 py-1 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50">
                                        Save
                                    </button>
                                    <button
                                        onClick={handleSubmit}
                                        className="px-4 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                                        Submit
                                    </button>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiPageForm;