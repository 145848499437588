"use client"

import React, {useState, useEffect, useCallback} from 'react';
import { useLocation } from "react-router-dom";
import {
    MenuIcon,
    XIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    TrashIcon

} from '@heroicons/react/outline';

import axios from 'axios';

import { Endpoints } from './consts';
import MultiPageForm from "./MultiPageForm";
import QRCodeModal from "./QRCodeModal";

function Artifact(
    id,
    userId,
    name,
    providedId,
    rawTextStorageKey,
    rawTextLanguage,
    createdAt,
    lastUpdatedAt,
    supportedGenders,
    supportedLanguages,
    hasAudio
) {
    this.id = id;
    this.userId = userId;
    this.name = name;
    this.providedId = providedId;
    this.rawTextStorageKey = rawTextStorageKey;
    this.rawTextLanguage = rawTextLanguage;
    this.createdAt = createdAt;
    this.lastUpdatedAt = lastUpdatedAt;
    this.supportedGenders = supportedGenders;
    this.supportedLanguages = supportedLanguages;
    this.hasAudio = hasAudio;
}

const UserHome = () => {
    const { state } = useLocation();
    const userId = state.id;
    const [allArtifacts, setAllArtifacts] = useState([]);
    const [filteredArtifacts, setFilteredArtifacts] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;

    const fetchArtifacts = useCallback(async () => {
        const fetchedArtifacts = await getArtifactsOfUser(userId)
        setAllArtifacts(fetchedArtifacts);
        setFilteredArtifacts(fetchedArtifacts)
    }, [userId]);

    useEffect(() => {
        const filtered = allArtifacts.filter(item =>
            Object.values(item).some(val =>
                val.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredArtifacts(filtered);
    }, [searchTerm, allArtifacts]);

    useEffect(() => {
        fetchArtifacts();
    }, [fetchArtifacts]);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleDelete = (artifact) => {
        const deleteArtifactUrl = `${process.env.REACT_APP_API_URL}/${Endpoints.DeleteArtifact}`
        axios.delete(deleteArtifactUrl, {
            data: {
                artifact
            }
        }).then((response) => {
            console.log("deleteArtifact response: ", response);
        }).catch((err) => {
            console.log("err calling deleteArtifact: ", err);
        })
    };

    const totalPages = Math.ceil(filteredArtifacts.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const artifactsOnCurrentPage = filteredArtifacts.slice(indexOfFirstItem, indexOfLastItem);

    const getArtifactsOfUser = async (userId) => {
        const listArtifactsUrl = `${process.env.REACT_APP_API_URL}/${Endpoints.ListArtifacts}/${userId}`
        let artifacts = [];
        try {
            const { data: response } = await axios.get(listArtifactsUrl);
            console.log(response);
            for (let i = 0; i < response.length; i++) {
                const artifact = response[i];
                artifacts.push(new Artifact(
                    artifact.id,
                    artifact.userId,
                    artifact.name,
                    artifact.providedId,
                    artifact.rawTextStorageKey,
                    artifact.rawTextLanguage,
                    artifact.createdAt,
                    artifact.lastUpdatedAt,
                    artifact.supportedGenders,
                    artifact.supportedLanguages,
                    artifact.hasAudio));
            }
        } catch (err) {
            console.log("listArtifacts error: ", err);
        }

        return artifacts;
    }

    return (
        <div className="flex flex-col h-screen">
            {/* Header */}
            <header className="w-screen bg-zinc-200 p-4 shadow-md flex fixed">
                <button
                    onClick={toggleSidebar}
                    className="mr-4 p-1 rounded hover:bg-indigo-600 transition-colors"
                >
                    {sidebarOpen ? <XIcon className='w-7'/> : <MenuIcon className='w-7'/>}
                </button>
                <h1 className="text-2xl font-bold">Sage Whisperer</h1>
            </header>

            <div className="flex flex-1 overflow-hidden y-[100%]">
                {/* Sidebar */}
                <aside className={`bg-gray-100 overflow-y-auto transition-all duration-300 ease-in-out py-20
                    ${ sidebarOpen ? 'w-48' : 'w-0' }`}>
                    <nav className="p-4">
                        <ul className="space-y-2">
                            <li><button className="block p-2 hover:bg-gray-200 rounded">Items</button></li>
                            <li><button className="block p-2 hover:bg-gray-200 rounded">Profile</button></li>
                            <li><button className="block p-2 hover:bg-gray-200 rounded">Billings</button></li>
                            <li><button className="block p-2 hover:bg-gray-200 rounded">Support</button></li>
                        </ul>
                    </nav>
                </aside>

                {/* Main content with table */}
                <main className="flex-1 p-4 overflow-y-auto my-20 h-screen">
                    <h2 className="text-xl font-semibold mb-4">All Items</h2>

                    {/* Search field and Create New button */}
                    <div className="mb-4 flex justify-between items-center">
                        <input
                            type="text"
                            placeholder="Search item"
                            className="w-2/3 p-2 border rounded"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <MultiPageForm userId={userId} artifactId=""/>
            </div>

            <div className="overflow-x-auto">
                        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                            <thead className="bg-gray-200">
                            <tr>
                                <th className="py-2 px-4 text-left">ID</th>
                                <th className="py-2 px-4 text-left">Name</th>
                                <th className="py-2 px-4 text-left">Status</th>
                                <th className="py-2 px-4 text-left">QR Code</th>
                                <th className="py-2 px-4 text-left">Edit or Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            {artifactsOnCurrentPage.map((artifact) => (
                                <tr key={artifact.id} className="border-b hover:bg-gray-50">
                                    <td className="py-2 px-4"></td>
                                    <td className="py-2 px-4 text-black text-left">{artifact.name}</td>
                                    <td className="py-2 px-4"></td>
                                    <td className="py-2 px-4">
                                        <QRCodeModal name={artifact.name} userId={artifact.userId} artifactId={artifact.id} hasAudio={artifact.hasAudio} />
                                    </td>
                                    <td className="py-2 px-4 flex flex-row">
                                        <MultiPageForm userId={artifact.userId} artifactId={artifact.id}/>
                                        <div className="p-4">
                                            <button onClick={() => handleDelete(artifact)}
                                                    className="mr-1 p-1 text-red-600 hover:text-red-800 bg-transparent">
                                                <TrashIcon className='w-5'/>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination */}
                    <div className="mt-4 flex justify-between items-center">
                        <button
                            onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
                            disabled={currentPage === 1}
                            className="px-4 py-2 bg-indigo-700 text-white rounded disabled:bg-gray-300"
                        >
                            <ChevronLeftIcon className='w-7 rounded' />
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button
                            onClick={() => setCurrentPage(page => Math.min(page + 1, totalPages))}
                            disabled={currentPage === totalPages}
                            className="px-4 py-2 bg-indigo-700 text-white rounded disabled:bg-gray-300 bg-transparent hover:bg-gray-800"
                        >
                            <ChevronRightIcon className='w-7 rounded' />
                        </button>
                    </div>
                </main>
            </div>
        </div>
    )
}


export default UserHome;