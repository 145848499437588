import React, { useState, useEffect } from 'react';
import {Link} from "react-scroll";

const LandingPage = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setIsMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const MenuIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
        </svg>
    );

    const CloseIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
    );

    const NavItems = ({ mobile }) => (
        <>
            <ul className='hidden md:flex'>
                <li><Link to="home" smooth={true} duration={500} className={`text-gray-600 hover:text-indigo-600 px-3 py-2 rounded-md text-sm font-medium ${mobile ? 'block' : ''}`}>Home</Link></li>
                <li><Link to="about" smooth={true} offset={-200} duration={500} className={`text-gray-600 hover:text-indigo-600 px-3 py-2 rounded-md text-sm font-medium ${mobile ? 'block' : ''}`}>About</Link></li>
                <li><Link to="pricing" smooth={true} offset={-50} duration={500} className={`text-gray-600 hover:text-indigo-600 px-1 py-2 rounded-md text-sm font-medium ${mobile ? 'block' : ''}`}>Pricing</Link></li>
            </ul>
            <a href="/login" className={`text-gray-600 hover:text-indigo-600 px-3 py-2 rounded-md text-sm font-medium ${mobile ? 'block' : ''}`}>Sign In</a>
            <a href="/signup" className={`bg-indigo-600 text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-indigo-700 ${mobile ? 'block text-center mt-2' : 'inline-block'}`}>Sign Up</a>
        </>
    );

    return (
        <div className="min-h-screen bg-gray-100">
            <nav className="bg-white shadow-md">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <a href="/" className="text-3xl font-bold text-indigo-600">SageWhisper</a>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                <NavItems />
                            </div>
                        </div>
                        <div className="md:hidden">
                            <button
                                onClick={toggleMenu}
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-indigo-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            >
                                {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                            </button>
                        </div>
                    </div>
                </div>
                {isMenuOpen && (
                    <div className="md:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                            <NavItems mobile />
                        </div>
                    </div>
                )}
            </nav>

            <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <div className="px-4 py-6 sm:px-0">
                    <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">Welcome to AudioGuide</h1>
                    <p className="text-lg sm:text-xl text-gray-600 mb-8">Discover the world through immersive audio experiences</p>
                    <a href="/signup" className="bg-indigo-600 text-white px-6 py-3 rounded-md text-lg font-medium hover:bg-indigo-700 inline-block">Get Started</a>
                </div>
            </main>
        </div>
    );
};

export default LandingPage;