export const SERVER_DNS = "https://api.dev.sagewhisper.io/";

export const Endpoints = {
    SignUp: "signup",
    SignIn: "signin",
    CreateArtifact: "createArtifact",
    UpdateArtifact: "updateArtifact",
    ListArtifacts: 'listArtifacts',
    DeleteArtifact: 'deleteArtifact',
    SubmitWorkload: 'submitWorkload',
    DescribeArtifact: 'describeArtifact',
}

export const Pages = {
    SignUp: "/signup",
    LogIn: "/login",
    UserHome: "/userhome"
}

export const OriginalLanguageIdentifier = "original";

export class Language {
    ticker;
    name;
    constructor(ticker, name) {
        this.ticker = ticker;
        this.name = name;
    }
}

export const AllLanguages= [
    new Language("AR", "Arabic"),
    new Language("BG", "Bulgarian"),
    new Language("CS", "Czech"),
    new Language("DA", "Danish"),
    new Language("DE", "German"),
    new Language("EL", "Greek"),
    // new Language("EN", "English"),
    new Language("EN-GB", "English (British)"),
    new Language("EN-US", "English (American)"),
    new Language("ES", "Spanish"),
    new Language("ET", "Estonian"),
    new Language("FI", "Finnish"),
    new Language("FR", "French"),
    new Language("HU", "Hungarian"),
    new Language("ID", "Indonesian"),
    new Language("IT", "Italian"),
    new Language("JA", "Japanese"),
    new Language("KO", "Korean"),
    new Language("LT", "Lithuanian"),
    new Language("LV", "Latvian"),
    new Language("NB", "Norwegian"),
    new Language("NL", "Dutch"),
    new Language("PL", "Polish"),
    new Language("PT", "Portuguese"),
    new Language("RO", "Romanian"),
    new Language("RU", "Russian"),
    new Language("SK", "Slovak"),
    new Language("SL", "Slovenian"),
    new Language("SV", "Swedish"),
    new Language("TR", "Turkish"),
    new Language("UK", "Ukrainian"),
    // new Language("ZH", "Chinese"),
    new Language("ZH-HANS", "Chinese (Simplified)"),
    new Language("ZH-HANT", "Chinese (Traditional)")
];

export const LanguageTickersToNames = new Map(AllLanguages.map(language => [language.ticker, language.name]));
export const LanguageNamesToTickers = new Map(AllLanguages.map(language => [language.name, language.ticker]));