import React from 'react';
import About from './components/About';
import AllInOne from './components/AllInOne';
import Footer from './components/Footer';
import LandingPage from './components/LandingPage'
import Pricing from './components/Pricing';

function App() {
  return (
    <>
      <LandingPage />
      <About />
      <AllInOne />
      <Pricing />
      <Footer />
    </>
  );
}

export default App;
